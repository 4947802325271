export enum MaintenanceRecVisitLengthMinutesOptions {
    THIRTY = 'THIRTY',
    FORTYFIVE = 'FORTYFIVE',
    SIXTY = 'SIXTY'
}

export enum VisitFrequencyOptions {
    BI_WEEKLY = 'BI_WEEKLY',
    TRI_WEEKLY = 'TRI_WEEKLY',
    MONTHLY = 'MONTHLY'
}
