import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Select from './Select';
import Checkbox from './Checkbox';
import PhoneNumberInput from './PhoneNumberInput';
import MultiSelect from './MultiSelect';
import OptionSelector from './OptionSelector';

enum InputSize {
    extraSmall = 'extra-small',
    small = 'small',
    medium = 'medium',
    large = 'large',
    fullWidth = 'full-width',
    singleCharacter = 'single-character'
}

const Input = styled.input<{error?: boolean; inputSize?: InputSize}>`
  padding: 10px;
  margin: 8px 8px 8px 10px;
  border: 1px solid ${props => (props.error ? props.theme.text.error : props.theme.shades.lightGrey)};
  border-radius: 3px;
  background-color: ${props => props.theme.shades.offWhite};
  width: ${props => {
        if (props.inputSize === InputSize.extraSmall) {
            return '80px';
        } else if (props.inputSize === InputSize.small) {
            return '100px';
        } else if (props.inputSize === InputSize.medium) {
            return '200px';
        } else if (props.inputSize === InputSize.large) {
            return '300px';
        } else if (props.inputSize === InputSize.singleCharacter) {
            return '45px';
        }
        return '100%';
    }};
  height: ${props => props.inputSize === InputSize.singleCharacter ? '45px' : 'auto'};  
  max-width: ${props => props.inputSize === InputSize.fullWidth ? '100%' : '400px'};
  :active {
    outline: none;
    border-color: ${props => (props.error ? props.theme.text.error : props.theme.primary.medium)};
  }

  &:disabled {
    background-color: #ececec;
    cursor: not-allowed;
  }

  &:disabled+label {
    cursor: not-allowed;
  }

  &::placeholder {
    color: #c0c0c0;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${props => props.theme.text.error};
`;

export const InputContainer = ({ children, error, className }: PropsWithChildren<{ error?: any, className?: string }>) => (
    <StyledInputContainer className={className}>
        {children}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </StyledInputContainer>
);

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 10px;
  width: 100%;
  max-width: 400px;
  text-align: left;
  color: ${props => props.theme.primary.medium};
`;

export const TextArea = styled.textarea`
  padding: 10px;
  margin: 8px 0;
  border: 1px solid ${props => props.theme.shades.lightGrey};
  border-radius: 10px;
  background-color: ${props => props.theme.shades.offWhite};
  width: 100%;
  max-width: 400px;
  height: 200px;
`;

const Radio = styled(Input).attrs({ type: 'radio' })`
  width: 20px;
  height: 20px;
`;

export {
    Input,
    Select,
    PhoneNumberInput,
    Checkbox,
    Radio,
    InputSize,
    MultiSelect,
    OptionSelector
};
