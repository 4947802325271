var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Select from './Select';
import Checkbox from './Checkbox';
import PhoneNumberInput from './PhoneNumberInput';
import MultiSelect from './MultiSelect';
import OptionSelector from './OptionSelector';
var InputSize;
(function (InputSize) {
    InputSize["extraSmall"] = "extra-small";
    InputSize["small"] = "small";
    InputSize["medium"] = "medium";
    InputSize["large"] = "large";
    InputSize["fullWidth"] = "full-width";
    InputSize["singleCharacter"] = "single-character";
})(InputSize || (InputSize = {}));
var Input = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 10px;\n  margin: 8px 8px 8px 10px;\n  border: 1px solid ", ";\n  border-radius: 3px;\n  background-color: ", ";\n  width: ", ";\n  height: ", ";  \n  max-width: ", ";\n  :active {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &:disabled {\n    background-color: #ececec;\n    cursor: not-allowed;\n  }\n\n  &:disabled+label {\n    cursor: not-allowed;\n  }\n\n  &::placeholder {\n    color: #c0c0c0;\n  }\n"], ["\n  padding: 10px;\n  margin: 8px 8px 8px 10px;\n  border: 1px solid ", ";\n  border-radius: 3px;\n  background-color: ", ";\n  width: ", ";\n  height: ", ";  \n  max-width: ", ";\n  :active {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &:disabled {\n    background-color: #ececec;\n    cursor: not-allowed;\n  }\n\n  &:disabled+label {\n    cursor: not-allowed;\n  }\n\n  &::placeholder {\n    color: #c0c0c0;\n  }\n"])), function (props) { return (props.error ? props.theme.text.error : props.theme.shades.lightGrey); }, function (props) { return props.theme.shades.offWhite; }, function (props) {
    if (props.inputSize === InputSize.extraSmall) {
        return '80px';
    }
    else if (props.inputSize === InputSize.small) {
        return '100px';
    }
    else if (props.inputSize === InputSize.medium) {
        return '200px';
    }
    else if (props.inputSize === InputSize.large) {
        return '300px';
    }
    else if (props.inputSize === InputSize.singleCharacter) {
        return '45px';
    }
    return '100%';
}, function (props) { return props.inputSize === InputSize.singleCharacter ? '45px' : 'auto'; }, function (props) { return props.inputSize === InputSize.fullWidth ? '100%' : '400px'; }, function (props) { return (props.error ? props.theme.text.error : props.theme.primary.medium); });
export var StyledInputContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 15px;\n  width: 100%;\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 15px;\n  width: 100%;\n"])));
export var ErrorMessage = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  color: ", ";\n"], ["\n  font-size: 12px;\n  color: ", ";\n"])), function (props) { return props.theme.text.error; });
export var InputContainer = function (_a) {
    var children = _a.children, error = _a.error, className = _a.className;
    return (_jsxs(StyledInputContainer, __assign({ className: className }, { children: [children, error && _jsx(ErrorMessage, { children: error.message })] })));
};
export var Label = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  font-weight: bold;\n  margin-top: 5px;\n  margin-bottom: 0;\n  margin-left: 10px;\n  width: 100%;\n  max-width: 400px;\n  text-align: left;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  font-weight: bold;\n  margin-top: 5px;\n  margin-bottom: 0;\n  margin-left: 10px;\n  width: 100%;\n  max-width: 400px;\n  text-align: left;\n  color: ", ";\n"])), function (props) { return props.theme.primary.medium; });
export var TextArea = styled.textarea(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 10px;\n  margin: 8px 0;\n  border: 1px solid ", ";\n  border-radius: 10px;\n  background-color: ", ";\n  width: 100%;\n  max-width: 400px;\n  height: 200px;\n"], ["\n  padding: 10px;\n  margin: 8px 0;\n  border: 1px solid ", ";\n  border-radius: 10px;\n  background-color: ", ";\n  width: 100%;\n  max-width: 400px;\n  height: 200px;\n"])), function (props) { return props.theme.shades.lightGrey; }, function (props) { return props.theme.shades.offWhite; });
var Radio = styled(Input).attrs({ type: 'radio' })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n"], ["\n  width: 20px;\n  height: 20px;\n"])));
export { Input, Select, PhoneNumberInput, Checkbox, Radio, InputSize, MultiSelect, OptionSelector };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
