export var MaintenanceRecVisitLengthMinutesOptions;
(function (MaintenanceRecVisitLengthMinutesOptions) {
    MaintenanceRecVisitLengthMinutesOptions["THIRTY"] = "THIRTY";
    MaintenanceRecVisitLengthMinutesOptions["FORTYFIVE"] = "FORTYFIVE";
    MaintenanceRecVisitLengthMinutesOptions["SIXTY"] = "SIXTY";
})(MaintenanceRecVisitLengthMinutesOptions || (MaintenanceRecVisitLengthMinutesOptions = {}));
export var VisitFrequencyOptions;
(function (VisitFrequencyOptions) {
    VisitFrequencyOptions["BI_WEEKLY"] = "BI_WEEKLY";
    VisitFrequencyOptions["TRI_WEEKLY"] = "TRI_WEEKLY";
    VisitFrequencyOptions["MONTHLY"] = "MONTHLY";
})(VisitFrequencyOptions || (VisitFrequencyOptions = {}));
