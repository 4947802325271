import styled from 'styled-components';
import { theme } from '../../theme';

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Option = styled.p`
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
