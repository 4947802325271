import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import Home from '../../pages/Home';
import LoginForm from '../../pages/Login/LoginForm';
import SimpleSignUpForm from '../../pages/Login/SimpleSignUpForm';
import { RootState } from '../../state/store';
import ForgotPassword from '../../pages/Login/ForgotPasswordForm';
import EmailVerification from '../../pages/Login/EmailVerification';
import { Sidebar, SidebarDivider, SidebarExternalLinkContainer, SidebarNavLink, SidebarPrivacyLinks, SidebarSubHeading } from '@apps/common-ui';
import { ReactComponent as Logo } from '../../assets/BsLogoWhite.svg';
import Forms from '../../pages/Forms';
import Support from '../../pages/Support';
import Form from '../../pages/Form';
import NotFound from '../../components/common/NotFound';
import { PageContainer } from '../../components/Page';
import Arrow from '../../assets/SidebarArrow.svg';
import { Icon, SidebarLinksContainer, SidebarLogo } from '@apps/common-ui/src/Sidebar/index.styles';
import Account from '../../pages/Account';
import { Button } from '@apps/common-ui/src/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Appointments from '../../pages/Appointments';
import CommunitiesRedirect from '../../components/CommunitiesRedirect';
import Mixpanel from '../../services/Mixpanel';
import ExerciseWeeklySchedule from '../../pages/Exercises/PhysicalExercises/ExerciseWeeklySchedule';
import ExercisesForDay from '../../pages/Exercises/PhysicalExercises/ExercisesForDay';
import ModulesList from '../../pages/Learning/ModulesList';
import SubmodulesList from '../../pages/Learning/SubmodulesList';
import { ActionPlanTypes, LearningTypes, useLogoutOnStorageClear } from '@apps/common-utilities';
import { MedicalCondition, TenantUser } from '../../types/models';
import usePageAnalytics from '../../hooks/usePageAnalytics';
import HealthieRedirect from '../../components/HealthieRedirect';
import { USER_GROUP_CODES } from '@apps/common-ui/src/types/constants';
import ActionPlanPage from '../../pages/Action';
import { ActionPlanDetails } from '../../pages/Action/ActionPlanDetails';
import EmailSupport from '../../pages/Support/Support-email';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchExercisePlan } from '../../state/reducers/coaching/actions';
import VideoCall from '../../pages/VideoCall';
import { IExerciseWeekSummary } from '@apps/common-utilities/src/types/exerciseTypes';
import Onboarding from '../../pages/Onboarding';
import RMTWeeklySchedule from '../../pages/Exercises/RMTExercises/RMTWeeklySchedule';
import RMTForDay from '../../pages/Exercises/RMTExercises/RMTForDay';
import { logout } from '../../state/reducers/session';

export type RouterProps = {
  loggedIn: boolean;
  tenantUser: TenantUser | null;
  forms: any[];
  exercisePlan?: IExerciseWeekSummary;
  course?: LearningTypes.IUserCourse;
  actionPlan?: ActionPlanTypes.IActionPlan;
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
    const { loggedIn } = useSelector((state: RootState) => state.session);

    let next = '/login';
    if (window.location.pathname !== '/') {
        next = `/login?next=${window.location.href}`;
    }
    // If the user is logged in, show the child components
    return loggedIn ? <PageContainer>{children}</PageContainer> : <Navigate to={next} replace />;
}
function LayoutWrapper({ children }: { children: React.ReactNode }) {
    return <PageContainer>{children}</PageContainer>;
}

const AppRouter = ({ loggedIn, tenantUser, forms, exercisePlan, course, actionPlan }: RouterProps) => {
    const { user } = useSelector((state: RootState) => state.session);
    const { callAction: getExercisePlan, loading } = useActionLoader(fetchExercisePlan, { hideToast: true });
    const { callAction: logouUser } = useActionLoader(logout);
    const [hideNavbarFromOnboarding, setHideNavbarFromOnboarding] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const onboardingComplete = user?.onboardingComplete;
    const [hasRMT, setHasRMT] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    useEffect(() => {
        const onboardingSkipped = localStorage.getItem('onboardingSkipped');
        const isOnboardingPage = location.pathname === '/onboarding';
        const isNavigatingToAllowedPath = location.pathname.startsWith('/support')
      || location.pathname.startsWith('/forms/');

        setHideNavbarFromOnboarding(!onboardingSkipped);

        if ((onboardingSkipped || user?.onboardingComplete) && isOnboardingPage) {
            navigate('/');
        } else if (!isOnboardingPage
        && !isNavigatingToAllowedPath
        && !onboardingSkipped
        && !user?.onboardingComplete) {
            navigate('/onboarding');
        }
    }, [location.pathname, user]);

    useEffect(() => {
    // refresh  exercise plan if user is logged in,
    // don't refresh on sub pages where they are unlikely to need it
    // don't refresh if loading or the user doesn't exist
        if (user && !loading && location.pathname.split('/').length === 2) {
            getExercisePlan({ userId: user.id });
        }
    }, [location.pathname, user]);

    useEffect(() => {
        const rmt = exercisePlan?.dailySummaries.some((day) => day.totalRMTExercises > 0);
        setHasRMT(!!rmt);
    }, [exercisePlan]);
    useLogoutOnStorageClear(loggedIn, logouUser);
    usePageAnalytics();
    return (
        <>
            {loggedIn && (!hideNavbarFromOnboarding || onboardingComplete) && (
            <Sidebar minWidth="220px">
                <SidebarLinksContainer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <SidebarLogo>
                        <Logo />
                    </SidebarLogo>
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_home_link')} to="/">Home</SidebarNavLink>
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_appointments_link')} to="/appointments">Appointments</SidebarNavLink>
                    <SidebarNavLink to="/exercises" onClick={() => Mixpanel.track('web_sidebar_exercises_link')}>Exercise</SidebarNavLink>
                    { hasRMT && <SidebarNavLink to="/breather" onClick={() => Mixpanel.track('web_sidebar_rmt_link')}>Breather</SidebarNavLink>}
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_courses_link')} to="/learning/modules">Education</SidebarNavLink>
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_forms_link')} to="/forms" badge={forms.length ? forms.length : ''}>Forms</SidebarNavLink>
                    {!!actionPlan && <SidebarNavLink to="/action-plan" onClick={() => Mixpanel.track('web_sidebar_action-plan_link')}>Action Plan</SidebarNavLink>}
                    <SidebarDivider />
                    <SidebarSubHeading>MY ACCOUNT</SidebarSubHeading>
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_account_link')} to="/account">
                        <FontAwesomeIcon icon={faUser as IconProp} />
                        Account
                    </SidebarNavLink>
                    {!!tenantUser?.groupCode && tenantUser.groupCode.includes(USER_GROUP_CODES.healthieBilling) && (
                    <SidebarExternalLinkContainer>
                        <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_healthie_billing_link')} to="/healthie">
                            Billing Portal
                            <Icon src={Arrow} />
                        </SidebarNavLink>
                    </SidebarExternalLinkContainer>
            )}
                    <SidebarNavLink onClick={() => Mixpanel.track('web_sidebar_support_link')} to="/support">
                        <Button>
                            <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
                            Get Help
                        </Button>
                    </SidebarNavLink>
                </SidebarLinksContainer>
                <SidebarPrivacyLinks>
                    <a
                      onClick={() => Mixpanel.track('web_terms_of_use_click')}
                      href="https://www.breathesuite.com/termsofuse"
                    >
                        Terms & Conditions
                    </a>
                    <a
                      onClick={() => Mixpanel.track('web_privacy_policy_click')}
                      href="https://www.breathesuite.com/privacypolicy"
                    >
                        Privacy Policy
                    </a>
                </SidebarPrivacyLinks>
            </Sidebar>
            )}
            <main className="main-content">
                <Routes>
                    <Route path="/sign-up" element={<SimpleSignUpForm />} />
                    <Route path="/reset-password" element={<ForgotPassword />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/email-verify" element={<EmailVerification />} />
                    <Route path="/meeting" element={<VideoCall />} />
                    <Route
                      path="/onboarding"
                      element={
                          <ProtectedRoute><Onboarding /></ProtectedRoute>
                      }
                    />
                    <Route
                      path="/action-plan"
                      element={<ProtectedRoute><ActionPlanPage /></ProtectedRoute>}
                    />
                    <Route
                      path="/action-plan/details/:zone"
                      element={<ProtectedRoute><ActionPlanDetails /></ProtectedRoute>}
                    />
                    <Route
                      path="/forms"
                      element={<ProtectedRoute><Forms /></ProtectedRoute>}
                    />
                    <Route
                      path="/forms/:formId"
                      element={<ProtectedRoute><Form /></ProtectedRoute>}
                    />
                    <Route
                      path="/"
                      element={<ProtectedRoute><Home /></ProtectedRoute>}
                    />
                    <Route
                      path="/learning/modules"
                      element={<ProtectedRoute><ModulesList /></ProtectedRoute>}
                    />
                    <Route
                      path="/learning/modules/:moduleId"
                      element={<ProtectedRoute><SubmodulesList /></ProtectedRoute>}
                    />
                    <Route
                      path="/exercises"
                      element={<ProtectedRoute><ExerciseWeeklySchedule /></ProtectedRoute>}
                    />
                    <Route
                      path="/exercises/:date"
                      element={<ProtectedRoute><ExercisesForDay /></ProtectedRoute>}
                    />
                    <Route
                      path="/breather"
                      element={<ProtectedRoute><RMTWeeklySchedule /></ProtectedRoute>}
                    />
                    <Route
                      path="/breather/:date"
                      element={<ProtectedRoute><RMTForDay /></ProtectedRoute>}
                    />
                    <Route
                      path="/communities"
                      element={<ProtectedRoute><CommunitiesRedirect /></ProtectedRoute>}
                    />
                    <Route
                      path="/support"
                      element={<LayoutWrapper><Support /></LayoutWrapper>}
                    />
                    <Route
                      path="/support/email-support"
                      element={<LayoutWrapper><EmailSupport /></LayoutWrapper>}
                    />
                    <Route
                      path="/account"
                      element={<ProtectedRoute><Account /></ProtectedRoute>}
                    />
                    <Route
                      path="/appointments"
                      element={<ProtectedRoute><Appointments /></ProtectedRoute>}
                    />
                    <Route
                      path="/healthie"
                      element={<ProtectedRoute><HealthieRedirect /></ProtectedRoute>}
                    />
                    <Route
                      path="*"
                      element={<ProtectedRoute><NotFound /></ProtectedRoute>}
                    />
                </Routes>
            </main>
        </>
    );
};

export default AppRouter;
