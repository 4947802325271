import { useEffect } from 'react';
var useLogoutOnStorageClear = function (loggedIn, logout) {
    useEffect(function () {
        var checkLocalStorage = function () {
            var len = localStorage.length;
            if (len <= 0 && loggedIn) {
                logout();
            }
        };
        checkLocalStorage();
        window.addEventListener('storage', checkLocalStorage);
        return function () {
            window.removeEventListener('storage', checkLocalStorage);
        };
    }, [loggedIn, logout]);
};
export default useLogoutOnStorageClear;
