import { LearningTypes } from '../..';
import { ExerciseEquipment, ExerciseType, ExerciseCategory } from './exerciseTypes';
import { MaintenanceRecVisitLengthMinutesOptions, VisitFrequencyOptions } from './maintenanceRecommendation';
export var equipment = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[ExerciseEquipment.WEIGHTS] = 'Weights',
        _a[ExerciseEquipment.BANDS] = 'Bands',
        _a[ExerciseEquipment.CHAIR] = 'Chair',
        _a[ExerciseEquipment.ASSISTED_DEVICES] = 'Assisted Devices',
        _a[ExerciseEquipment.STEP_STAIRS] = 'Step/Stairs',
        _a);
    return labelMap[key];
};
export var exerciseType = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[ExerciseType.SETS_AND_REPS] = 'Sets and Reps',
        _a[ExerciseType.INTERVALS] = 'Interval',
        _a[ExerciseType.HOLD] = 'Hold',
        _a[ExerciseType.SETS_AND_BREATHS] = 'Sets and Breaths',
        _a);
    return labelMap[key];
};
export var exerciseCategory = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[ExerciseCategory.STRENGTH] = 'Strength',
        _a[ExerciseCategory.CARDIO] = 'Cardio',
        _a[ExerciseCategory.FLEXIBILITY] = 'Flexibility',
        _a[ExerciseCategory.BREATHING] = 'Breathing',
        _a[ExerciseCategory.RMT] = 'RMT Breather',
        _a);
    return labelMap[key];
};
export var pauseReason = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[LearningTypes.CourseUserPauseReason.NON_PAYMENT] = 'Non Payment',
        _a[LearningTypes.CourseUserPauseReason.ILLNESS] = 'Illness',
        _a);
    return labelMap[key];
};
export var maintenanceRecVisitLengthMinutesDisplay = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[MaintenanceRecVisitLengthMinutesOptions.THIRTY] = 30,
        _a[MaintenanceRecVisitLengthMinutesOptions.FORTYFIVE] = 45,
        _a[MaintenanceRecVisitLengthMinutesOptions.SIXTY] = 60,
        _a);
    return labelMap[key];
};
export var maintenanceRecVisitFrequencyOptionsDisplay = function (key) {
    var _a;
    var labelMap = (_a = {},
        _a[VisitFrequencyOptions.BI_WEEKLY] = 'Bi-weekly',
        _a[VisitFrequencyOptions.TRI_WEEKLY] = 'Tri-weekly',
        _a[VisitFrequencyOptions.MONTHLY] = 'Monthly',
        _a);
    return labelMap[key];
};
